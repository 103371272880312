body {
    font-family: 'Poppins', sans-serif;
  }
  
  button, input {
    font-family: inherit;
  }

  
  .wrapper {
    /* border: 1px solid black; */
    width: auto;
    height: 100%;
  }

  .homescreen-carousel .splide {
    padding: 0;
    margin: 0;
    width: 100%;
    /* width: 65%; */
    /* margin: 0 auto; */
    position: relative;
    /* border: 1px solid red; */
  }
  
  .homescreen-carousel .splide__slide {
    background: #eee;
    height: 100%;
    overflow: hidden;
  }
  
  .homescreen-carousel .splide__slide img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
  }

  .homescreen-carousel .splide .splide__arrow svg {
    fill: rgba(14, 12, 12, 0.689);
    width: 50px;
    height: 50px;
  }

  .homescreen-carousel .splide .splide__arrow {
    background: none;
    border-radius: 0;
    height: 130px;
    width: 100px;
    /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1); */
    transition: all 0.2s ease;
    opacity: 1;
  }
  

  