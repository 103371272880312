.card {
    width: auto;
    height: 254px;
    border-radius: 20px;
    position: relative;
    padding: 1.8rem;
    /* border: 2px solid white; */
    transition: 0.5s ease-out;
    overflow: visible;
    cursor: pointer;
   }
   
   .card-details {
    color: black;
    height: 100%;
    gap: .5em;
    display: grid;
    place-content: center;
   }
   
   .card-button {
    transform: translate(-50%, 125%);
    width: 60%;
    border-radius: 1rem;
    border: none;
    background-color: #2E3191;
    color: #fff;
    font-size: 1rem;
    padding: .5rem 1rem;
    position: absolute;
    left: 50%;
    bottom: 0;
    opacity: 0;
    transition: 0.3s ease-out;
   }
   
   .text-body {
    color: rgb(134, 134, 134);
   }
   
   /*Text*/
   .text-title {
    font-size: 1.5em;
    font-weight: bold;
   }
   
   /*Hover*/
   .card:hover {
    /* border-color: #2E3191; */
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.25);
   }
   
   /* .card:hover .card-button {
    transform: translate(-50%, 50%);
    opacity: 1;
   } */