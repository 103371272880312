body {
    font-family: 'Poppins', sans-serif;
  }
  
  button, input {
    font-family: inherit;
  }
  
  label {
    margin-right: 1rem;
  }
  
  input {
    font-family: inherit;
    height: 1.5rem;
    width: 4rem;
    margin-right: 3px;
  }
  
  label span {
    margin-right: 4px;
  }
  
  .examples {
    padding: 2rem 0;
  }
  
  .wrapper {
    /* border: 1px solid black; */
    width: auto;
    height: 450px;
  }

  .splide {
    padding: 0;
    margin: 0;
    width: 100%;
    
    /* width: 65%; */
    /* margin: 0 auto; */
    position: relative;
    /* border: 1px solid red; */
  }
  
  .splide__slide {
    background: #eee;
    height: 350px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .splide__slide img {
    width: 100%;
    height: 470px;
    object-fit: cover;
  }
  
  .splide--nav {
    margin-top: 1rem;
  }