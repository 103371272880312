/* The switch - the box around the slider */
.corporate-fare-switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3em;
    height: 26px;
  }
  
  /* Hide default HTML checkbox */
  .corporate-fare-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .corporate-fare-slider {
    position: absolute;
    cursor: pointer;
    inset: 0;
    border: 2px solid #202265;
    border-radius: 50px;
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .corporate-fare-slider:before {
    position: absolute;
    content: "";
    height: 1.1em;
    width: 1.1em;
    left: 0.1em;
    bottom: 0.1em;
    background-color: #202265;
    border-radius: inherit;
    transition: all 0.4s cubic-bezier(0.23, 1, 0.320, 1);
  }
  
  .corporate-fare-switch input:checked + .corporate-fare-slider {
    border: 2px solid red;
  }

  .corporate-fare-switch input:checked + .corporate-fare-slider:before{
    background-color: red;
  } 
  
  .corporate-fare-switch input:checked + .corporate-fare-slider:before {
    transform: translateX(1.3em);
  }

  .premium-glow {
    margin-left: 5px;
    padding: 10px;
    pointer-events: none;
    width: fit-content;
    border: none;
    border-radius: 40px;
    background: linear-gradient(to right,#bf953f,#fcf6ba,#b38728,#fbf5b7,#aa771c);
    /* background: white; */
    /* background: green; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 0.6em;
    color: red;
    font-weight: 600;
    cursor: pointer;
    position: relative;
    z-index: 2;
    transition-duration: 3s;
    background-size: 200% 200%;
    text-transform: uppercase;
    position: absolute;
    left: 20%;

    animation-name: example;
    animation-duration: 1s;

    /* Animation will be repeated infinitely */
    animation-iteration-count: infinite;
  }

  @keyframes example {
    from {
      /* transform: scale(1); */
      /* transition-duration: 3s; */
      /* animation: gradient 5s ease infinite; */
      /* background-position: left; */
      /* opacity: 0; */
    }

    to {
      /* transform: scale(0.95); */
      /* transition-duration: 3s; */
      /* animation: gradient 5s ease infinite; */
      /* background-position: right; */
      /* opacity: 1; */
    }
  }

  @media only screen and (max-width: 600px) {
    .corporate-fare-switch {
      min-width: 3em;
    }
  }