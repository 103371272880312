  /* .testimonials-carousel{
    border: 1px solid red;
    width: 100vw;
  } */

  .testimonials-carousel .splide {
    padding: 0 0 50px 0;
    margin: 0 auto;
    position: relative;
    /* border: 1px solid red; */
  }

  .testimonials-carousel .splide__slide{
    background: none;
    height: auto;
    padding: 70px;
  }
  
  .testimonials-carousel .splide__slide > div {
    display: block;
    /* width: 100%; */
    /* border-radius: 8px; */
    transition: transform 400ms;
    transform: scale(0.7);
    transform-origin: center center;
    opacity: 0.6;
    margin: 0 auto;
    /* border: 1px solid red; */
  }
  
  .testimonials-carousel .is-prev > div{
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 25%;
    border-bottom-right-radius: 25%;
  }
  .testimonials-carousel .is-next > div{
    border-top-right-radius: 25%;
    border-bottom-left-radius: 25%;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  
  
  .testimonials-carousel .is-active > div {
    transform: scale(1);
    border-radius: 10px;
    opacity: 1;
    position: relative;
    z-index: 10;
    /* -webkit-box-shadow: 1px 0px 47px -17px rgba(89,177,230,1);
    -moz-box-shadow: 1px 0px 47px -24px rgba(89,177,230,1);
    box-shadow: 1px 0px 47px -17px rgba(89,177,230,1); */
  }
  
  .testimonials-carousel .splide .splide__arrows--ltr {
    position: absolute;
    bottom: 60%;
    /* border: 1px solid brown; */
    /* top: 20px; */
    height: 30px;
    transform: none;
    border-radius: unset;
    width: 100%;
    opacity: 0.9;
    margin: 0 auto;
    z-index: 11;
  }
  .testimonials-carousel .splide .splide__arrow svg {
    fill: #202265;
    width: 24px;
    height: 24px;
  }

  .testimonials-carousel .splide .splide__arrow svg:hover{
    fill: red;
  }

  .testimonials-carousel .splide .splide__arrow {
    background: white;
    height: 40px;
    width: 40px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    opacity: 1;
  }

  
  .testimonials-carousel .splide__arrow.splide__arrow--prev {
    left: 30%;
  }
  
  .testimonials-carousel .splide__arrow.splide__arrow--next {
  
    right: 30%;
  }
  
  @media screen and (max-width: 600px) {
    .splide{
      width: 100%;
    }
  
    .testimonials-carousel .splide__arrow.splide__arrow--prev {
      left: 0;
    }
    
    .testimonials-carousel .splide__arrow.splide__arrow--next {
    
      right: 0;
    }

    .testimonials-carousel .splide__slide{
      padding: 10px;
    }
  }