.appstore-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border: 3px solid rgb(189, 189, 189);
    background-color: #202265;
    border-radius: 50px;
    gap: 5px;
    cursor: pointer;
    transition: all 0.3s;
    box-shadow: 0px 0px 100px -30px rgb(255, 255, 255);
  }
  .appstore-button svg {
    width: 25px;
  }
  .appstore-button svg path {
    fill: white;
    transition: all 0.3s;
  }
  .button-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: white;
    transition: all 0.3s;
  }
  .top-text {
    font-size: 11px;
    /* font-weight: 600; */
  }
  .bottom-text {
    font-size: 15px;
    font-weight: 600;
  }
  
  .appstore-button:hover {
    background-color: rgb(236, 236, 236);
    border: 3px solid rgb(138, 138, 138);
  }
  .appstore-button:hover .button-text {
    color: black;
  }
  .appstore-button:hover svg path {
    fill: black;
  }