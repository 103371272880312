  #FR {
    fill: #2E3191;
    cursor: pointer;
  }
  #FR:hover {
    fill: #040761;
  }
  #IN {
    fill: #2E3191;
    cursor: pointer;
  }
  #IN:hover {
    fill: #040761;
  }

  #LK {
    fill: #2E3191;
    cursor: pointer;
  }
  #LK:hover {
    fill: #040761;
  }

  #GB {
    fill: #2E3191;
    cursor: pointer;
  }
  #GB:hover {
    fill: #040761;
  }

  #US {
    fill: #2E3191;
    cursor: pointer;
  }
  #US:hover {
    fill: #040761;
  }

  #MY {
    fill: #2E3191;
    cursor: pointer;
  }
  #MY:hover {
    fill: #040761;
  }

  #CA {
    fill: #2E3191;
    cursor: pointer;
  }
  #CA:hover {
    fill: #040761;
  }

  #BR {
    fill: #2E3191;
    cursor: pointer;
  }
  #BR:hover {
    fill: #040761;
  }

  #JP {
    fill: #2E3191;
    cursor: pointer;
  }
  #JP:hover {
    fill: #040761;
  }

  #AU {
    fill: #2E3191;
    cursor: pointer;
  }
  #AU:hover {
    fill: #040761;
  }
  /* #ID {
    fill: #F00;
  }
  #ID:hover {
    fill: #800000;
  }
  #GL {
    fill: #FFF;
  }
  #GL:hover {
    fill: #BBB;
  }
  #BR {
    fill: #00F;
  }
  #BR:hover {
    fill: #000080;
  } */
  
  .ag-canvas {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  
    position: relative;
    visibility: visible;
  
    /* width: 100%;
    height: 100%; */
    padding: 3rem 1rem;
    opacity: 1;
    transition: opacity 0.5s;
  }
  .ag-canvas_svg {
    display: block;
    width: 100%;
    height: auto;
    max-height: 100%;
    margin-left: 1rem;
  }
  .ag-canvas_svg path {
    fill: #DFD7F9;
    fill-opacity: 1;
    stroke: gray;
    stroke-opacity: 1;
    stroke-width: 0.5;
    transition: 0.5s;
  }
  
  @media screen and (max-width: 767px) {
    .ag-canvas {
      -webkit-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      height: auto;
      padding: 4rem 1rem 1rem;
    }
    .ag-canvas_svg {
      max-height: calc(100% - 6rem);
      margin-left: 0;
    }
  }
  